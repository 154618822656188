/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

import 'flowbite';

document.querySelectorAll('[data-delete-url]').forEach(element => {
    element.addEventListener('click', (event) => {
        const url = element.getAttribute('data-delete-url');
        const targetLink = document.querySelector(`#delete-item a[href]`);
        targetLink.setAttribute('href', url);
    });
});

document.getElementById('paymentReminderButton').onclick = function() {
    document.getElementById('paymentReminderModal').classList.remove('hidden');
};

document.getElementById('cancelReminder').onclick = function() {
    document.getElementById('paymentReminderModal').classList.add('hidden');
};

document.getElementById('confirmReminder').onclick = function() {
    // Add your logic to handle the payment reminder action here
    // For example, you might want to make an AJAX request to send the reminder
    const url = document.getElementById('confirmReminder').dataset.paymentReminderUrl;
    window.location.href = url;
};